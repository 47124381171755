import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import Masonry from "react-masonry-css"
import Image from "gatsby-image"

//components
import Section from "../components/Section"
import SEO from "../components/SEO"
//css
import "../css/masonry.css"

//masonry
const breakPoint = {
  default: 4,
  1200: 3,
  980: 2,
  740: 1,
}

const RealisationTemplate = ({ pageContext, data }) => {
  const [counter, setCounter] = useState(0)
  const [format, setFormat] = useState(0)
  const [visible, setVisible] = useState(false)

  const r = useRef(null)
  r.current = { counter, setCounter }
  const s = useRef(null)
  s.current = { format, setFormat }

  useEffect(() => {
    visible
      ? document.body.classList.add("active")
      : document.body.classList.remove("active")
  })

  console.log()

  return (
    <>
      {data.c.metaDsc ? (
        <SEO
          title={data.c.title}
          dsc={data.c.metaDsc.metaDsc}
          pageContext={pageContext}
        />
      ) : (
        <SEO title={data.c.title} pageContext={pageContext} />
      )}
      <Section classname="realisation" container>
        <Masonry
          breakpointCols={breakPoint}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {data.d.nodes.map((item, index) => {
            if (
              item.image.file.details.image.width -
                item.image.file.details.image.height >
              0
            ) {
              console.log()
              return (
                <div
                  key={index}
                  onClick={() => {
                    r.current.setCounter((r.current.counter = index))
                    s.current.setFormat((s.current.format = 1))
                    setVisible(!visible)
                  }}
                  onKeyDown={() => {
                    r.current.setCounter((r.current.counter = index))
                    s.current.setFormat((s.current.format = 1))
                    setVisible(!visible)
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <Image fluid={item.image.fluid} />
                </div>
              )
            } else {
              return (
                <div
                  key={index}
                  onClick={() => {
                    r.current.setCounter((r.current.counter = index))
                    s.current.setFormat((s.current.format = 0))
                    setVisible(!visible)
                  }}
                  onKeyDown={() => {
                    r.current.setCounter((r.current.counter = index))
                    s.current.setFormat((s.current.format = 0))
                    setVisible(!visible)
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <Image fluid={item.image.fluid} />
                </div>
              )
            }
          })}
        </Masonry>
        <div
          className={visible ? "colorBox visible" : "colorBox"}
          onClick={() => {
            setVisible(!visible)
          }}
          onKeyDown={() => {
            setVisible(!visible)
          }}
          role="button"
          tabIndex={0}
        >
          <div className={s.current.format ? "horizontale" : "verticale"}>
            <Image
              className="colorboxBg"
              fluid={data.d.nodes[r.current.counter].image.fluid}
            />
          </div>
        </div>
      </Section>
    </>
  )
}
export const query = graphql`
  query($contentful_id: String, $node_locale: String) {
    c: contentfulPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      title
      metaDsc {
        metaDsc
      }
    }
    d: allContentfulRealisation(filter: { node_locale: { eq: "fr" } }) {
      nodes {
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
          file {
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
    }
  }
`

export default RealisationTemplate
